import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {PortalRights, User, UserRoleOnProject} from '@models/User.model';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Preference} from '@fielddata.io/utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: User;
  private baseUrl = environment.portalApiUrl;
  private userUrl = this.baseUrl + 'user-details/all-users';
  private userService = this.baseUrl + 'user-details';

  constructor(private http: HttpClient) {
  }

  public getUser(): Observable<User> {
    const requestUrl: string = this.userService + '/';
    return this.http.get<User>(requestUrl);
  }

  public getUserDetails(): User {
    return this.user;
  }

  public setUser(user: User): void{
    this.user = user;
  }

  public createUser(userPayload: User, initialLanguage: string): Observable<HttpResponse<User>> {
    const requestUrl: string = this.userService;
    return this.http.post<HttpResponse<User>>(requestUrl, {
      user: userPayload,
      language: initialLanguage
    }, {
      observe: 'response' as 'body'
    });
  }

  public getAllUsers(query: string): Observable<User[]> {
    if (query) {
      return this.http.get<User[]>(`${this.userUrl}?query=${query}`);
    } else {
      return this.http.get<User[]>(`${this.userUrl}?query=`);
    }
  }

  public editUser(editForm: User): any {
    return this.http.put<any>(this.userService + '/edit', editForm);
  }

  public getAllUsersOfOrg(): Observable<User[]> {
    const requestUrl: string = this.userService + '/org-users';
    return this.http.get<User[]>(requestUrl);
  }

  public getAllBasicUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.userService}/basic-users`);
  }

  public getAllUsersExcludingManager(projectId: number | string): Observable<User[]> {
    const requestUrl = `${this.userService}/org-users/proj/${projectId}`;
    return this.http.get<User[]>(requestUrl);
  }

  public checkUser(userEmail: string): Observable<User> {
    const requestUrl = this.userService + '/checkUser/' + userEmail;
    return this.http.get<User>(requestUrl);
  }

  public getUsersByProjects(projId: number | string): Observable<User[]> {
    return this.http.get<User[]>(this.userService + '/proj/' + projId);
  }

  public getUserRoleByProject(projId: number | string): Observable<UserRoleOnProject[]> {
    return this.http.get<UserRoleOnProject[]>(
      this.userService + '/proj/' + projId + '/user-roles'
    );
  }

  public updateUserSettings(preferences: Preference): Observable<Preference>  {
    const url = `${environment.portalApiUrl}user-settings/`;
    return this.http.put<Preference>(url, preferences);
  }

  public getInitialUserSettings(): Observable<Preference>  {
    const url = `${environment.portalApiUrl}user-settings/initial-values`;
    return this.http.get<Preference>(url);
  }

  public geCurrentUserSettings(): Observable<Preference> {
    const url = `${environment.portalApiUrl}user-settings/`;
    return this.http.get<Preference>(url);
  }

  public getPortalRights(): Observable<PortalRights[]> {
    return this.http.get<PortalRights[]>(
      `${this.baseUrl}portal-rights/all-rights`
    );
  }

  public savePortalRights(rights): Observable<PortalRights[]> {
    return this.http.put<PortalRights[]>(
      `${this.baseUrl}portal-rights/user-portal-right`,
      rights
    );
  }

  public getUserOnPortalRights(): Observable<PortalRights[]> {
    return this.http.get<PortalRights[]>(
      `${this.baseUrl}user-details/portal-rights`
    );
  }

  public updatePortalRights(portalRights: any): Observable<User>  {
    const url = `${this.baseUrl}/portal-rights/user-portal-right`;
    return this.http.put<User>(url, portalRights);
  }

  public resetBasicUsers(userIds: number[]): Observable<void> {
    return this.http.post<void>(`${environment.portalApiUrl}user-settings/basic-user/reset?ids=${userIds}`, null);
  }
}
